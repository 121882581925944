import axios from "axios";
import { ElMessage } from "element-plus";
import { hideLoading } from "@/utils/loading";
const _timeout = 10000;

function get(url, params) {
  const promise = new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        timeout: _timeout
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        hideLoading();
        if (error.response.status != 401) {
          ElMessage.error(error.message);
        }
        reject(error.message);
      });
  });
  return Promise.resolve(promise);
}

function post(url, data, params) {
  const promise = new Promise((resolve, reject) => {
    let p = { timeout: _timeout };
    if (params) {
      p.params = params;
    }
    axios
      .post(url, data, p)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        hideLoading();
        if (error.response.status != 401) {
          ElMessage.error(error.message);
        }
        reject(error.message);
      });
  });
  return Promise.resolve(promise);
}

function put(url, data, params) {
  const promise = new Promise((resolve, reject) => {
    let p = { timeout: _timeout };
    if (params) {
      p.params = params;
    }
    axios
      .put(url, data, p)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        hideLoading();
        if (error.response.status != 401) {
          ElMessage.error(error.message);
        }
        reject(error.message);
      });
  });
  return Promise.resolve(promise);
}

function del(url, data, params) {
  const promise = new Promise((resolve, reject) => {
    let p = { data: data, timeout: _timeout };
    if (params) {
      p.params = params;
    }
    axios
      .delete(url, p)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        hideLoading();
        if (error.response.status != 401) {
          ElMessage.error(error.message);
        }
        reject(error.message);
      });
  });
  return Promise.resolve(promise);
}

export default {
  get,
  post,
  put,
  del
};

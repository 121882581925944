import request from "@/utils/request";

function code() {
  return request.get("/verify/code");
}

function login(id, code, username, password) {
  return request.post("/contacts/login", {
    id: id,
    code: code,
    username: username,
    password: password
  });
}

function getinfo() {
  return request.get("/contacts/info");
}

function logout() {
  return request.del("/admin/logout");
}

function change(old_passwd, new_passwd) {
  return request.put("/user/passwd", {
    old_passwd: old_passwd,
    new_passwd: new_passwd
  });
}

export default {
  code,
  login,
  getinfo,
  logout,
  change
};

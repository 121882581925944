import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-41b9f77c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "bottom_menu"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 1
};
var _hoisted_4 = {
  key: 2
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_main = _resolveComponent("el-main");
  var _component_el_text = _resolveComponent("el-text");
  var _component_el_row = _resolveComponent("el-row");
  var _component_el_aside = _resolveComponent("el-aside");
  var _component_el_container = _resolveComponent("el-container");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, null, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_main), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.menu_data, function (item, i) {
        return _openBlock(), _createBlock(_component_el_aside, {
          key: i,
          "class": "bottom_menu_aside"
        }, {
          "default": _withCtx(function () {
            return [_createVNode(_component_el_row, {
              style: {
                "margin-bottom": "15px"
              }
            }, {
              "default": _withCtx(function () {
                return [_createVNode(_component_el_text, {
                  "class": "bottom_menu_aside_title"
                }, {
                  "default": _withCtx(function () {
                    return [_createTextVNode(_toDisplayString(item.title), 1)];
                  }),
                  _: 2
                }, 1024)];
              }),
              _: 2
            }, 1024), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, function (sub, j) {
              return _openBlock(), _createBlock(_component_el_row, {
                key: j,
                style: {
                  "margin-top": "5px"
                }
              }, {
                "default": _withCtx(function () {
                  return [sub.type == 'text' ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_el_row, null, {
                    "default": _withCtx(function () {
                      return [_createVNode(_component_el_text, {
                        "class": "bottom_menu_aside_subtitle"
                      }, {
                        "default": _withCtx(function () {
                          return [_createTextVNode(_toDisplayString(sub.value), 1)];
                        }),
                        _: 2
                      }, 1024)];
                    }),
                    _: 2
                  }, 1024)])) : _createCommentVNode("", true), sub.type == 'page' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_text, {
                    "class": "bottom_menu_aside_sublink",
                    onClick: function onClick($event) {
                      return $options.onPage(sub);
                    }
                  }, {
                    "default": _withCtx(function () {
                      return [_createTextVNode(_toDisplayString(sub.value), 1)];
                    }),
                    _: 2
                  }, 1032, ["onClick"])])) : _createCommentVNode("", true), sub.type == 'cate' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_el_text, {
                    "class": "bottom_menu_aside_sublink",
                    onClick: function onClick($event) {
                      return $options.onCate(sub);
                    }
                  }, {
                    "default": _withCtx(function () {
                      return [_createTextVNode(_toDisplayString(sub.value), 1)];
                    }),
                    _: 2
                  }, 1032, ["onClick"])])) : _createCommentVNode("", true)];
                }),
                _: 2
              }, 1024);
            }), 128))];
          }),
          _: 2
        }, 1024);
      }), 128)), _createVNode(_component_el_main)];
    }),
    _: 1
  })]);
}
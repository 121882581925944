import axios from "axios";
import router from "@/router/index";
import token from "@/utils/token";

//请求拦截
axios.interceptors.request.use(
  (config) => {
    config.url = "/api" + config.url;
    config.headers["Content-Type"] = "application/json;charset=UTF-8";
    let auth = token.get();
    if (auth) config.headers["Authorization"] = auth;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (!response.data || !response.data.code) {
        //消息不完整
        response.status = 202;
      } else if (response.data.code !== 200) {
        //弹出自定义
        const err = new Error();
        response.status = response.data.code;
        err.response = response;
        err.message = response.data.msg;
        throw err;
      } else {
        //返回数据
        return response.data.data ? response.data.data : null;
      }
    }
    //状态错误
    const err = new Error();
    err.response = response;
    throw err;
  },
  (error) => {
    switch (error.response.status) {
      case 100:
        error.message = "客户端应继续其请求";
        break;
      case 101:
        error.message = "服务器根据客户端的请求切换协议。";
        break;
      case 200:
        error.message = "请求成功。";
        break;
      case 201:
        error.message = "成功请求并创建了新的资源。";
        break;
      case 202:
        error.message = "已经接受请求，但未处理完成。";
        break;
      case 203:
        error.message = "非授权信息。";
        break;
      case 204:
        error.message = "服务器成功处理，但未返回内容。";
        break;
      case 205:
        error.message =
          "服务器处理成功，用户终端（例如：浏览器）应重置文档视图。";
        break;
      case 206:
        error.message = "服务器成功处理了部分GET请求。";
        break;
      case 300:
        error.message = "请求的资源可包括多个位置。";
        break;
      case 301:
        error.message = "请求的资源已被永久的移动到新URI。";
        break;
      case 302:
        error.message = "临时移动。";
        break;
      case 303:
        error.message = "查看其它地址。";
        break;
      case 304:
        error.message = "所请求的资源未修改。";
        break;
      case 305:
        error.message = "所请求的资源必须通过代理访问。";
        break;
      case 306:
        error.message = "已经被废弃的HTTP状态码。";
        break;
      case 307:
        error.message = "临时重定向。";
        break;
      case 400:
        error.message = "客户端请求的语法错误，服务器无法理解。";
        break;
      case 401:
        error.message = "请先登录系统。";
        router.push("/login").then();
        break;
      case 402:
        error.message = "暂未定义。";
        break;
      case 403:
        error.message = "服务器理解请求客户端的请求，但是拒绝执行此请求。";
        break;
      case 404:
        error.message = "服务器无法根据客户端的请求找到资源（网页）。";
        break;
      case 405:
        error.message = "客户端请求中的方法被禁止。";
        break;
      case 406:
        error.message = "服务器无法根据客户端请求的内容特性完成请求。";
        break;
      case 407:
        error.message = "请求要求代理的身份认证。";
        break;
      case 408:
        error.message = "服务器等待客户端发送的请求时间过长。";
        break;
      case 409:
        error.message = "服务器处理请求时发生了冲突。";
        break;
      case 410:
        error.message = "客户端请求的资源已经不存在。";
        break;
      case 411:
        error.message =
          "服务器无法处理客户端发送的不带Content-Length的请求信息。";
        break;
      case 412:
        error.message = "客户端请求信息的先决条件错误。";
        break;
      case 413:
        error.message = "由于请求的实体过大，服务器无法处理，因此拒绝请求。";
        break;
      case 414:
        error.message = "请求的URI过长（URI通常为网址），服务器无法处理。";
        break;
      case 415:
        error.message = "服务器无法处理请求附带的媒体格式。";
        break;
      case 416:
        error.message = "客户端请求的范围无效。";
        break;
      case 417:
        error.message = "服务器无法满足Expect的请求头信息。";
        break;
      case 500:
        error.message = "服务器内部错误，无法完成请求。";
        break;
      case 501:
        error.message = "服务器不支持请求的功能，无法完成请求。";
        break;
      case 502:
        error.message =
          "作为网关或者代理工作的服务器尝试执行请求时，从远程服务器接收到了一个无效的响应。";
        break;
      case 503:
        error.message =
          "由于超载或系统维护，服务器暂时的无法处理客户端的请求。";
        break;
      case 504:
        error.message = "充当网关或代理的服务器，未及时从远端服务器获取请求。";
        break;
      case 505:
        error.message = "服务器不支持请求的HTTP协议的版本，无法完成处理。";
        break;
      default:
        error.message = "错误(" + error.response.status + ")：" + error.message;
        break;
    }
    return Promise.reject(error);
  }
);

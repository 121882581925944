export default {
  data: function data() {
    return {
      image: require("@/assets/beian.png"),
      copyright: process.env.VUE_APP_COPYRIGHT,
      icp: process.env.VUE_APP_ICP,
      name: process.env.VUE_APP_BEIAN_NAME,
      code: process.env.VUE_APP_BEIAN_CODE
    };
  },
  methods: {
    icpClick: function icpClick() {
      window.open("https://beian.miit.gov.cn");
    },
    beiAnClick: function beiAnClick() {
      window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=" + this.code);
    }
  }
};
export const staticRoutes = [
  {
    path: "/",
    meta: {
      title: "主页"
    },
    component: () => import("@/views/home")
  },
  {
    path: "/login",
    meta: {
      title: "登录"
    },
    component: () => import("@/views/login")
  },
  {
    path: "/goods",
    meta: {
      title: "商品"
    },
    component: () => import("@/views/goods")
  },
  {
    path: "/details",
    meta: {
      title: "商品详情"
    },
    component: () => import("@/views/details")
  },
  {
    path: "/cart",
    meta: {
      title: "购物车"
    },
    component: () => import("@/views/cart")
  },
  {
    path: "/about",
    meta: {
      title: "关于"
    },
    component: () => import("@/views/about")
  },
  {
    path: "/:pathMatch(.*)*",
    meta: {
      title: "404"
    },
    component: () => import("@/views/404/index.vue")
  }
];

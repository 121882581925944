import "core-js/modules/es.function.name.js";
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_menu_item = _resolveComponent("el-menu-item");
  var _component_el_sub_menu = _resolveComponent("el-sub-menu");
  var _component_el_menu = _resolveComponent("el-menu");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_menu, {
    mode: "horizontal"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_menu_item, {
        index: "index",
        onClick: _cache[0] || (_cache[0] = function ($event) {
          return $options.onPage('/');
        })
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("首页")];
        }),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "goods",
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return $options.onPage('/goods');
        })
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("商品列表")];
        }),
        _: 1
      }), _createVNode(_component_el_sub_menu, {
        index: "category"
      }, {
        title: _withCtx(function () {
          return [_createTextVNode("商品品类")];
        }),
        "default": _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, function (item, i) {
            return _openBlock(), _createBlock(_component_el_menu_item, {
              index: i,
              key: i,
              onClick: function onClick($event) {
                return $options.onPage('/goods?category=' + item.id);
              }
            }, {
              "default": _withCtx(function () {
                return [_createTextVNode(_toDisplayString(item.name), 1)];
              }),
              _: 2
            }, 1032, ["index", "onClick"]);
          }), 128))];
        }),
        _: 1
      }), _createVNode(_component_el_menu_item, {
        index: "about",
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return $options.onPage('/about');
        })
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("关于我们")];
        }),
        _: 1
      })];
    }),
    _: 1
  })]);
}
import request from "@/utils/request";

const basic = {
  all: "0", //全部
  shape: "1", //外观
  grade: "2", //品级
  category: "3", //大类
  style: "4", //品类
  craft: "5", //工艺
  inlay: "6" //镶嵌
};

function get(group) {
  return request.get("/basic", {
    group: group
  });
}

export default {
  basic,
  get
};
